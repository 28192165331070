import React, { FC } from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

const Swap: FC<SvgIconProps> = (props) => (
	<SvgIcon { ...props } viewBox="0 0 24 24">
		<path d="M6.1393 11.86L3.3593 14.65C3.1693 14.85 3.1693 15.16 3.3593 15.36L6.1393 18.15C6.4493 18.47 6.9893 18.24 6.9893 17.8V16H12.9993C13.5493 16 13.9993 15.55 13.9993 15C13.9993 14.45 13.5493 14 12.9993 14H6.9893V12.21C6.9893 11.76 6.4493 11.54 6.1393 11.86ZM20.6493 8.64997L17.8693 5.85997C17.5593 5.53997 17.0193 5.76997 17.0193 6.20997V7.99997H10.9993C10.4493 7.99997 9.9993 8.44997 9.9993 8.99997C9.9993 9.54997 10.4493 9.99997 10.9993 9.99997H17.0093V11.79C17.0093 12.24 17.5493 12.46 17.8593 12.14L20.6393 9.34997C20.8393 9.15997 20.8393 8.83997 20.6493 8.64997Z" />
	</SvgIcon>
)

export default Swap;
