import React, { FC } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";


const DriverLogo = styled('img')({
    width: '100%',
    height: '100%',
})

const DriversBox: FC<{ drivers: DriverBid[], off?: boolean }> = ({ drivers, off = false }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', opacity: off ? 0.3 : 1 }}>
            <Box
                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}
            >
                {drivers.slice(0, 3).map(t => (
                    <Box
                        key={t.name}
                        sx={{
                          width: 30, height: 30, borderRadius: '50%',
                          overflow: 'hidden', mr: 1, '&:last-child': {mr: 0},
                          border: (theme) => `1px solid ${theme.palette.common.N100}`
                        }}
                    >
                        <DriverLogo src={t.logo} title={t.name} alt={t.name} />
                    </Box>
                ))}
            </Box>
            {drivers.length > 3 && (
                <Typography variant="caption" color="common.N700" sx={{ ml: 1 }}>+{drivers.length - 3} more</Typography>
            )}
        </Box>
    )
}

export default DriversBox;
