import React, {FC, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import Link from "@mui/material/Link";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import OutlinedInput from "@mui/material/OutlinedInput";
import Avatar from "@mui/material/Avatar";
import styled from "@mui/system/styled";
import logo from '../../assets/Logo.png';
import AppMenu from "../common/AppMenu";


const SearchInput = styled(OutlinedInput)(({ theme }) => ({
	backgroundColor: theme.palette.common.N100,
	height: 54,
	paddingLeft: 16,
	paddingRight: 16,
	borderRadius: 28,
	borderStyle: 'none',
	fontWeight: 500,
	fontSize: 15,
	lineHeight: '30px',
	'&:hover': {
		borderStyle: 'solid',
	},
	'&.Mui-focused': {
		backgroundColor: 'transparent',
		borderColor: theme.palette.common.N500
	},
	'&:active': {
		backgroundColor: 'transparent'
	}
}));

const Logo = styled(Avatar)({
	width: 42,
	marginRight: 24,
});

const Header: FC = () => {
	const [search, setSearch] = useState<string>('');
	const inputRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	const handleSearch = () => {
		if(search.length === 66) {
			navigate(`/swap/${search}`);
		} else {
			navigate(`/trader/${search}`)
		}
		if(inputRef.current) {
			inputRef.current.blur();
		}
	}
	return (
		<Box
			sx={{width: 1, mt: 3.5, display: 'grid', gridTemplateColumns: { lg: '1fr 1fr 1fr', xs: '1fr 1fr' } , gridColumnGap: '16px'}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
				{/*
 // @ts-ignore */}
				<Logo src={logo} component={RouterLink} to="/" />
				<Typography color="common.N900" component={RouterLink} to="/"  variant="h2" sx={{ mr: 3, cursor: 'pointer', textDecoration: 'none' }}>Mayan Auctions</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<AppMenu />
			</Box>
		</Box>
	);
}

export default Header;
