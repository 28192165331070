import React, { FC } from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

const AuctionIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon { ...props } viewBox="0 0 20 20">
		<path d="M3.3335 16.6666V7.49998H6.25016V16.6666H3.3335ZM3.3335 6.24998V3.33331H6.25016V6.24998H3.3335ZM8.54183 16.6666V9.99998H11.4585V16.6666H8.54183ZM8.54183 8.74998V5.83331H11.4585V8.74998H8.54183ZM13.7502 16.6666V12.5H16.6668V16.6666H13.7502ZM13.7502 11.25V8.33331H16.6668V11.25H13.7502Z"/>
	</SvgIcon>
)

export default AuctionIcon;
