import React, { FC } from  'react';
import Button from "@mui/material/Button";
import {SxProps} from "@mui/material";
import Link from "@mui/material/Link";
import styled from "@mui/system/styled";
import OpenInNew from "@mui/icons-material/OpenInNew";
import {typography} from "../../../theme";

type SwapDetailsButtonProps = {
	sx?: SxProps,
	sourceTx: string,
	closed?: boolean
}

const StyledButton = styled(Button, {
	shouldForwardProp: (propName: PropertyKey) => propName !== 'closed'
})<{ closed: boolean }>(({ theme, closed }) => ({
	backgroundColor: closed ? theme.palette.common.N100 : theme.palette.common.N300,
	height: 30,
	padding: '4px 12px',
	borderRadius: 15,
	minWidth: 114,
	...typography.captionBold,
	'&:hover': {
		backgroundColor: `${theme.palette.common.N500} !important`,
	}
}))
const SwapDetailsButton: FC<SwapDetailsButtonProps> = ({ sx, sourceTx, closed = true }) => {

	return (
		<StyledButton
			closed={closed}
			// @ts-ignore
			component={Link}
			href={`${process.env.REACT_APP_MAYAN_EXPLORER_APP}/swap/${sourceTx}`}
			target="_blank"
			variant="contained"
			endIcon={<OpenInNew />}
			onClick={e => { e.stopPropagation() }}
			sx={sx}
		>
			Swap Details
		</StyledButton>
	)
}

export default SwapDetailsButton;
