import bscIcon from '../assets/bsc.png';
import ethIcon from '../assets/eth.png';
import solIcon from '../assets/SOL.png';
import polygonIcon from '../assets/polygon.png';
import avaxIcon from '../assets/avax-icon.png';
import aptosIcon from '../assets/aptos.png';
import jupiterLogo from '../assets/jupiter.png';
import prismLogo from '../assets/prism.png';
import {ethers} from "ethers";
import {PublicKey} from "@solana/web3.js";

export const chains : Array<Chain> = [
	{
		tokenBridgeAddress: 'wormDTUJ6AWPNvk59vGQbDvGJmqbDTdgWgAqcLBCgUb',
		wChainId: 1,
		chainId: 0,
		chainName: 'Solana',
		fullChainName: 'Solana Chain',
		rpcURL: 'https://solana-api.projectserum.com/',
		currencySymbol: 'SOL',
		blockExplorer: 'https://solscan.io',
		logoURI: solIcon,
		mayanContractAddress: '8LPjGDbxhW4G2Q8S6FvdvUdfGWssgtqmvsc63bwNFA7E',
		wrapContractAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
		explorerName: 'Solscan',
		baseToken: {
			mint: 'So11111111111111111111111111111111111111112',
			name: "SOL",
			symbol: "SOL",
			wChainId: 1,
			chainId:  0,
			decimals: 9,
			contract: ethers.constants.AddressZero,
			coingeckoId: "sol",
			wrappedAddress: 'So11111111111111111111111111111111111111112',
			logoURI:  solIcon,
		},
	},
	{
		tokenBridgeAddress: '0x3ee18B2214AFF97000D974cf647E7C347E8fa585',
		wChainId: 2,
		chainId: 1,
		chainName: 'Ethereum',
		fullChainName: 'Ethereum Mainnet',
		rpcURL: 'https://mainnet.infura.io/v3',
		currencySymbol: 'ETH',
		blockExplorer: 'https://etherscan.io',
		externalRpcURL: 'https://eth-mainnet.alchemyapi.io/v2/7GC0FyW8SoE7BOQGhPcNquNjKdOhAkgm',
		logoURI: ethIcon,
		mayanContractAddress: '0x80F53dcf568bE566F99Ab9F37eaa2B3AA10B3C95',
		wrapContractAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
		explorerName: 'Etherscan',
		baseToken: {
			mint: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
			name: "ETH",
			symbol: "ETH",
			wChainId: 2,
			chainId: 1,
			decimals: 18,
			contract:  ethers.constants.AddressZero,
			coingeckoId: "weth",
			wrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
			logoURI:  ethIcon,
		},
	},
	{
		tokenBridgeAddress: '0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7',
		wChainId: 4,
		chainId: 56,
		fullChainName: 'Binance Smart Chain Mainnet',
		chainName: 'BSC',
		blockExplorer: 'https://bscscan.com',
		currencySymbol: 'BNB',
		rpcURL: 'https://bsc-dataseed1.binance.org',
		logoURI: bscIcon,
		mayanContractAddress: '0x80F53dcf568bE566F99Ab9F37eaa2B3AA10B3C95',
		wrapContractAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
		explorerName: 'BscScan',
		baseToken: {
			mint: "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
			name: "BNB",
			symbol: "BNB",
			wChainId :4,
			chainId: 56,
			decimals: 18,
			contract: ethers.constants.AddressZero,
			coingeckoId: "wbnb",
			wrappedAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
			logoURI: bscIcon,
		}
	},
	{
		tokenBridgeAddress: '0x5a58505a96D1dbf8dF91cB21B54419FC36e93fdE',
		wChainId: 5,
		chainId: 137,
		chainName: 'Polygon',
		fullChainName: 'Polygon',
		rpcURL: 'https://polygon-rpc.com',
		currencySymbol: 'MATIC',
		blockExplorer: 'https://polygonscan.com',
		externalRpcURL: 'https://polygon-mainnet.g.alchemy.com/v2/rX2NW-TfXUgdctNEj2kRSv9lY2521Ks6',
		logoURI: polygonIcon,
		mayanContractAddress: '0x80F53dcf568bE566F99Ab9F37eaa2B3AA10B3C95',
		wrapContractAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
		explorerName: 'polygonscan',
		baseToken: {
			mint :"Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG",
			name: "Matic",
			symbol: "MATIC",
			wChainId: 5,
			chainId: 137,
			decimals: 18,
			coingeckoId: 'wmatic',
			contract: ethers.constants.AddressZero,
			wrappedAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
			logoURI: polygonIcon,
		}
	},
	{
		tokenBridgeAddress: '0x0e082F06FF657D94310cB8cE8B0D9a04541d8052',
		wChainId: 6,
		chainId: 43114,
		chainName: 'Avalanche',
		fullChainName: 'Avalanche (C-Chain)',
		logoURI: avaxIcon,
		// externalRpcURL: 'https://avalanche-mainnet.infura.io/v3/fb24d8d1782a43dc8336ca18d95e278a',
		rpcURL: 'https://api.avax.network/ext/bc/C/rpc',
		mayanContractAddress: '0x80F53dcf568bE566F99Ab9F37eaa2B3AA10B3C95',
		wrapContractAddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
		blockExplorer: 'https://snowtrace.io',
		currencySymbol: 'AVAX',
		baseToken: {
			mint :"KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE",
			name: "Avax",
			symbol: "AVAX",
			wChainId: 6,
			chainId: 43114,
			decimals: 18,
			coingeckoId: 'wrapped-avax',
			contract: ethers.constants.AddressZero,
			wrappedAddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
			logoURI: avaxIcon,
		},
		explorerName: 'SnowTrace',
	},
	{
		tokenBridgeAddress: '0x0b2402144Bb366A632D14B83F244D2e0e21bD39c',
		wChainId: 23,
		chainId: 42161,
		chainName: 'Arbitrum',
		fullChainName: 'Arbitrum (One)',
		logoURI: 'https://cdn.mayan.finance/arbitrum-logo.png',
		externalRpcURL: 'https://arb-mainnet.g.alchemy.com/v2/A-DLsshcXlzOMK3nvvfWfjvfAN0zwHqO',
		rpcURL: 'https://arb1.arbitrum.io/rpc',
		mayanContractAddress: '0x80F53dcf568bE566F99Ab9F37eaa2B3AA10B3C95',
		wrapContractAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
		blockExplorer: 'https://arbiscan.io',
		explorerName: 'Arbiscan',
		currencySymbol: 'ETH',
		baseToken: {
			mint :"CSD6JQMvLi46psjHdpfFdr826mF336pEVMJgjwcoS1m4",
			name: "ETH",
			symbol: "ETH",
			wChainId: 23,
			chainId: 42161,
			decimals: 18,
			coingeckoId: 'weth',
			contract: ethers.constants.AddressZero,
			wrappedAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
			logoURI: 'https://statics.mayan.finance/eth.png',
			realOriginChainId: 1,
			realOriginContractAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
		}
	},
	{
		tokenBridgeAddress: '0x576410486a2da45eee6c949c995670112ddf2fbeedab20350d506328eefc9d4f',
		wChainId: 22,
		chainId: 1998,
		chainName: 'Aptos',
		fullChainName: 'Aptos Chain',
		rpcURL: 'https://fullnode.mainnet.aptoslabs.com/v1',
		currencySymbol: 'APT',
		blockExplorer: 'https://explorer.solana.com/',
		logoURI: aptosIcon,
		mayanContractAddress: ethers.constants.AddressZero,
		wrapContractAddress: 'NONE1111111111111111111111111111111111111111111',
		explorerName: 'AptosExplorer',
		baseToken: {
			mint: '6LNeTYMqtNm1pBFN8PfhQaoLyegAH8GD32WmHU9erXKN',
			name: "APT",
			symbol: "APT",
			wChainId: 22,
			chainId:  1998,
			decimals: 8,
			contract: ethers.constants.AddressZero,
			coingeckoId: "aptos",
			wrappedAddress: 'NONE1111111111111111111111111111111111111111111',
			logoURI:  aptosIcon,
		},
	},
];

export const drivers: Driver[] = [
	{
		name: 'Jupiter',
		id: 'Jupiter',
		logo: jupiterLogo,
		address: new PublicKey("9YFeuz8q9WMSJpeNhNHj6LSbZnQ6BvDt5u9CV2ALhPWv")
	},
	{
		name: 'Prism',
		id: 'Prism',
		logo: prismLogo,
		address: new PublicKey("6X3ojqxk1cpjhcehK2SgTDt8m4SgeFrpFesXrfyW6bY8"),
	},
]

export const mayanExplorerApiURL = 'https://explorer-api.mayan.finance';
export const mayanRelayerApiURL = 'https://relayer.mayan.finance';
