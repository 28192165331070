import React, { FC } from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

const ExplorerIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon { ...props } viewBox="0 0 20 20">
		<path d="M2.5 6.6875V3.75C2.5 3.41667 2.625 3.125 2.875 2.875C3.125 2.625 3.41667 2.5 3.75 2.5H17.0833C17.4167 2.5 17.7083 2.625 17.9583 2.875C18.2083 3.125 18.3333 3.41667 18.3333 3.75V6.6875H2.5ZM8.125 17.4792V7.9375H12.7083V17.4792H8.125ZM13.9583 17.4792V7.9375H18.3333V16.2292C18.3333 16.5625 18.2083 16.8542 17.9583 17.1042C17.7083 17.3542 17.4167 17.4792 17.0833 17.4792H13.9583ZM3.75 17.4792C3.41667 17.4792 3.125 17.3542 2.875 17.1042C2.625 16.8542 2.5 16.5625 2.5 16.2292V7.9375H6.875V17.4792H3.75Z" />
	</SvgIcon>
)

export default ExplorerIcon;
