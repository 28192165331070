import React, { FC } from 'react';
import styled from "@mui/system/styled";
import Box, {BoxProps} from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Icon as LinkIcon } from "../OutLink";

const Root = styled(Box, {
	shouldForwardProp : (propName: PropertyKey) => propName !== 'underline'
})<{ link: boolean }>(({ theme, link }) => ({
	display: 'flex',
	alignItems: 'center',
}));

const Icon = styled('img')({
	width: 24,
	height: 24,
	borderRadius: '50%',
	marginRight: 8,
});


type SymbolIconProps = {
	logoURI: string,
	symbol: string,
	link?: boolean,
};

const SymbolIcon: FC<SymbolIconProps & BoxProps> = ({ symbol, logoURI, link = false }) => (
	<Root link={link}>
		<Icon src={logoURI} alt={symbol} />
		<Typography variant="body3">{symbol}</Typography>
		{link && <LinkIcon />}
	</Root>
);

export default SymbolIcon;


