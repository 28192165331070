import React, { FC } from "react";
import {useRequest} from "ahooks";
import { useNavigate } from 'react-router-dom';
import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import LiveAuctions from "./LiveAuctions";
import ClosedAuctions from "./ClosedAuctions";
// import Stat from './Stat';


const TopSection = styled(Box)(({theme}) => ({
	width: '100%',
	padding: '16px 24px',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: theme.palette.common.N600,
	backgroundColor: theme.palette.common.tWhiteBold,
	borderRadius: 24,
}));

const Container = styled(Box)({
	display: 'flex',
	alignItems: 'center',
});

const Home: FC = () => {


	return (
		<Box sx={{mx: 'auto', maxWidth: 1024}}>
			{/*<TopSection sx={{ mb: 6 }}>*/}
			{/*	<Container sx={{ mb: 4 }}>*/}
			{/*		<Typography variant="h1">Drivers Stats</Typography>*/}
			{/*	</Container>*/}
			{/*	<Stat />*/}
			{/*</TopSection>*/}
			<TopSection sx={{ mb: 6 }}>
				<LiveAuctions />
			</TopSection>
			<ClosedAuctions />
		</Box>
	);
}

export default Home;
