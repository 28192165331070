import React, { FC } from 'react';
import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "@mui/system/styled";
import ArrowRight from "../icons/ArrowRight";
import Right from "../icons/Right";

const Root = styled(Box)({
	display: 'flex',
	alignItems: 'center',
})
const Converted: FC<{ from: string, to: string, typography: TypographyProps }> = ({ from, to, typography }) => {
	return (
		<Root>
			<Typography {...typography}>{from}</Typography>
			<Right sx={{ mx: 1 }}  />
			<Typography {...typography}>{to}</Typography>
		</Root>
	)
}

export default Converted;
