import React, { FC } from 'react';
import SymbolIcon from "../SymbolIcon";
import Right from "../../icons/Right";
import Swap from "../../icons/Swap";
import Box from "@mui/material/Box";

const Chains: FC<{ data: SwapFullData }> = ({ data }) => {
	if (data.swapChain) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
				<Box sx={{ mr: 2 }} >
					<SymbolIcon logoURI={data.sourceChain.logoURI} symbol={data.sourceChain.chainName} />
				</Box>
				{data.sourceChain.chainId !== data.swapChain.chainId && (
					<>
						{data.sourceChain.chainId === data.destChain.chainId ? (
							<Swap sx={{ mr: 2 }} />
						) : (
							<Right sx={{ mr: 2 }} />
						)}
						<Box sx={{ mr: 2 }} >
							<SymbolIcon logoURI={data.swapChain.logoURI} symbol={data.swapChain.chainName} />
						</Box>
					</>
				)}
				{data.destChain.chainId !== data.sourceChain.chainId && data.destChain.chainId !== data.swapChain.chainId && (
					<>
						<Right sx={{ mr: 2 }} />
						<SymbolIcon logoURI={data.destChain.logoURI} symbol={data.destChain.chainName} />
					</>
				)}
			</Box>
		)
	}
	return null;
}

export default Chains;
