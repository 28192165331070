import React, { FC } from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

const Hourglass: FC<SvgIconProps> = (props) => (
    <SvgIcon { ...props } viewBox="0 0 24 25">
        <path d="M7.9 21H16.1V17.825C16.1 16.6583 15.7042 15.6542 14.9125 14.8125C14.1208 13.9708 13.15 13.55 12 13.55C10.85 13.55 9.87917 13.9708 9.0875 14.8125C8.29583 15.6542 7.9 16.6583 7.9 17.825V21ZM4 22.5V21H6.4V17.825C6.4 16.6583 6.70417 15.5875 7.3125 14.6125C7.92083 13.6375 8.76667 12.9333 9.85 12.5C8.76667 12.0667 7.92083 11.3583 7.3125 10.375C6.70417 9.39167 6.4 8.31667 6.4 7.15V4H4V2.5H20V4H17.6V7.15C17.6 8.31667 17.2958 9.39167 16.6875 10.375C16.0792 11.3583 15.2333 12.0667 14.15 12.5C15.2333 12.9333 16.0792 13.6375 16.6875 14.6125C17.2958 15.5875 17.6 16.6583 17.6 17.825V21H20V22.5H4Z"/>
    </SvgIcon>
)

export default Hourglass;
