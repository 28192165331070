import React from 'react';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

const fontName = 'Urbanist';

export const colors = {
	N000: '#1D2232',
	N100: '#2B2E3C',
	N300: '#44495B',
	N500: '#4F5E6B',
	N600: '#667079',
	N700: '#C9CACC',
	N900: '#FFFFFF',
	green: '#56BA46',
	lightGreen: '#2B4120',
	lightYellow: '#BBB632',
	red: '#F25641',
	lightRed: '#4A2E21',
	primary: '#2F98F9',
	darkPrimary: '#061032',
	alwaysWhite: '#FFFFFF',
	tableBg: '#152A4A',
	transparentBg: 'rgba(0, 0, 0, 0.2)',
	transparentBgDark: 'rgba(0, 0, 0, 0.4)',
	tBlueLight: 'rgba(137, 200, 247, 0.14)',
	tWhiteLight: 'rgba(212, 224, 255, 0.08)',
	tWhiteBold: 'rgba(181, 202, 255, 0.15)',
	buttonBackground: '#1F3250',
	toastBgRed: '#4A2B2B',
	toastBgNatural: '#2E334D',
	toastBgGreen: '#365439',
	background: 'linear-gradient(295.52deg, #161616 4.74%, #0B061C 88.17%)',
};

export const typography = {
	h1: {
		fontWeight: 700,
		lineHeight: '40px',
		fontSize: 30,
		fontFamily: fontName,
	},
	h2: {
		fontWeight: 700,
		lineHeight: '34px',
		fontSize: 25,
		fontFamily: fontName,
	},
	h3: {
		fontWeight: 700,
		lineHeight: '43px',
		fontSize: 20,
		fontFamily: fontName,
	},
	body0Bold: {
		fontWeight: 700,
		fontSize: 25,
		lineHeight: '35px',
		fontFamily: fontName,
	},
	body0: {
		fontWeight: 500,
		fontSize: 25,
		lineHeight: '35px',
		fontFamily: fontName,
	},
	body1Bold: {
		fontWeight: 700,
		fontSize: 21,
		lineHeight: '30px',
		fontFamily: fontName,
	},
	body1: {
		fontWeight: 500,
		fontSize: 21,
		lineHeight: '30px',
		fontFamily: fontName,
	},
	body2Bold: {
		fontWeight: 700,
		fontSize: 17,
		lineHeight: '27px',
		fontFamily: fontName,
	},
	body2: {
		fontWeight: 500,
		fontSize: 17,
		lineHeight: '27px',
		fontFamily: fontName,
	},
	body3Bold: {
		fontWeight: 700,
		fontSize: 15,
		lineHeight: '25px',
		fontFamily: fontName,
	},
	body3: {
		fontWeight: 500,
		fontSize: 15,
		lineHeight: '25px',
		fontFamily: fontName,
	},
	body4Bold: {
		fontWeight: 700,
		fontSize: 13,
		lineHeight: '27px',
		fontFamily: fontName,
	},
	body4: {
		fontWeight: 500,
		fontSize: 13,
		lineHeight: '27px',
		fontFamily: fontName,
	},
	captionBold: {
		fontWeight: 700,
		fontSize: 11,
		lineHeight: '22px',
		fontFamily: fontName,
		letterSpacing: 0
	},
	caption: {
		fontWeight: 500,
		fontSize: 11,
		lineHeight: '22px',
		fontFamily: fontName,
		letterSpacing: 0
	},
};

declare module '@mui/material/styles' {
	interface TypographyVariants {
		body0: React.CSSProperties;
		body0Bold: React.CSSProperties;
		body1Bold: React.CSSProperties;
		body2Bold: React.CSSProperties;
		body3: React.CSSProperties;
		body3Bold: React.CSSProperties;
		body4: React.CSSProperties;
		body4Bold: React.CSSProperties;
		captionBold: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		body0?: React.CSSProperties;
		body0Bold?: React.CSSProperties;
		body1Bold?: React.CSSProperties;
		body2Bold?: React.CSSProperties;
		body3?: React.CSSProperties;
		body3Bold?: React.CSSProperties;
		body4?: React.CSSProperties;
		body4Bold?: React.CSSProperties;
		captionBold?: React.CSSProperties;
	}
}

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		tablet: true;
		mobile: true;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body0: true;
		body0Bold: true;
		body1Bold: true;
		body2Bold: true;
		body3: true;
		body3Bold: true;
		body4: true;
		body4Bold: true;
		captionBold: true;
	}
}
declare module "@mui/material/styles/createPalette" {
	interface CommonColors {
		N000: string;
		N100: string;
		N300: string;
		N500: string;
		N600: string;
		N700: string;
		N900: string;
		lightGreen: string;
		red: string;
		lightRed: string;
		primary: string;
		lightYellow: string;
		darkPrimary: string;
		alwaysWhite: string;
		tableBg: string;
		transparentBg: string;
		transparentBgDark: string;
		tBlueLight: string;
		tWhiteLight: string;
		tWhiteBold: string;
		buttonBackground: string;
		toastBgRed: string;
		toastBgNatural: string;
		toastBgGreen: string;
		background: string,
	}
}


declare module '@mui/material/InputBase' {
	interface InputBasePropsSizeOverrides {
		large: true;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		withBg: true;
	}
}

const theme  = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			tablet: 760,
			mobile: 410,
		}
	},
	palette: {
		mode: 'dark',
		primary: {
			main: colors.primary,
			dark: colors.darkPrimary,
		},
		common: colors,
		text: {
			primary: colors.N900,
		},
		error: {
			main: colors.red,
		},
		background: {
			default: colors.background,
		}
	},
	typography,
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					margin: 0,
					padding: 0,
					boxSizing: 'border-box',
					fontFamily: fontName,
				},
				html: {
					// background: 'radial-gradient(60.6% 109.04% at 68.26% 25.83%, #232641 0%, #1D2232 100%)'
				},
				body: {
					WebkitTapHighlightColor: 'rgba(255, 255, 255, 0)',
					fontFamily: fontName,
					background: colors.background,
					'& .walletconnect-modal__base': {
						color: '#161616',
					},
				},
				button: {
					fontFamily: fontName,
				},
				input: {
					fontFamily: fontName,
					fontWeight: 400,
					fontSize: '14px',
					'::placeholder': {
						fontFamily: fontName,
						fontWeight: 400,
						fontSize: '14px',
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: colors.N500,
					marginTop: 12,
					marginBottom: 12,
				},
			}
		},
		MuiSkeleton: {
			styleOverrides: {
				rectangular: {
					borderRadius: 8,
				},
				text: {
					borderRadius: 8,
				}
			},
			defaultProps: {
				animation: 'wave',
				variant: 'rectangular',
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					padding: '16px 24px 24px',
					backgroundColor: colors.N100,
					backgroundImage: 'none',
					borderRadius: 24,
				},
				paperWidthSm: {
					width: 440,
				},
				paperWidthMd: {
					width: 500,
				},
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingLeft: 0,
					paddingRight: 0,
					paddingBottom: 8,
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingTop: 0,
					paddingRight: 0,
					paddingBottom: 0,
					'&:last-child': {
						paddingBottom: 0
					}
				},
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				root: {
					marginLeft: 16,
					// '& .MuiStepConnector-line': {
					// 	borderColor: colors.primary
					// }
				},
				line: {
					borderLeftWidth: 3,
					minHeight: 30,
					borderColor: colors.N500,
				},
				// completed: {
				// 	'&.MuiStepConnector-line': {
				// 		borderColor: colors.primary,
				// 	},
				// 	'& .MuiStepConnector-line': {
				// 		borderColor: colors.primary,
				// 	}
				// },
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				iconContainer: {
					paddingRight: 48,
				},
				// label: {
				// 	...typography.body2,
				// }
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					...typography.body2,
				},
				secondary: {
					color: colors.N700,
					...typography.caption,
				}
			}
		},
		MuiListItemAvatar: {
			styleOverrides: {
				root: {
					minWidth: 52,
				}
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingBottom: 0,
					paddingRight: 0,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: fontName,
					textTransform: 'none',
					transition: 'all 0.3s ease-out',
					'&.Mui-disabled' : {
						backgroundColor: colors.N300,
						color: '#FFF !important',
					},
				},
				sizeMedium: {
					fontFamily: fontName,
					fontWeight: 700,
					fontSize: 15,
					lineHeight: '25px',
					height: 39,
					padding: '10px 26px',
				},
				contained: {
					color: colors.N900,
					boxShadow: 'none',
					'&:hover': {
						backgroundColor: '#3385D0',
						boxShadow: 'none',
					},
					'&.Mui-disabled' : {
						backgroundColor: colors.N500,
						color: `${colors.N700} !important`,
					},
					'&.MuiButton-containedError': {
						'&:hover': {
							backgroundColor: '#CA3A26'
						},
					}
				},
				containedSizeMedium: {
					borderRadius: 20,
					minWidth: 145,
				},
				sizeSmall: {
					height: 31,
					borderRadius: 40,
					paddingLeft: 20,
					paddingRight: 20,
					...typography.body4,
				},
				outlinedSizeMedium: {
					borderRadius: 20,
					minWidth: 145,
				},
				textSizeMedium: {
					padding: '2px 20px',
					...typography.body4,
				},
				sizeLarge: {
					...typography.body2Bold,
					textTransform: 'none',
					height: 51,
					padding: '18px 31px',
					minWidth: 175,
					paddingTop: 12,
					paddingBottom: 12,
				},
				containedSizeLarge: {
					borderRadius: 40,
				},
				outlinedSizeLarge: {
					borderRadius: 27,
				},
				startIcon: {
					marginRight: 10,
					marginLeft: 0,
				},
				endIcon: {
					marginRight: 0,
					marginLeft: 10,
				},
			},
			variants: [
				{
					props: { variant: 'withBg' },
					style: {
						color: colors.primary,
						backgroundColor: colors.transparentBgDark,
					},
				},
			],
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 24,
					backgroundColor: colors.N100,
					boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25);',
					backgroundImage: 'none',
				},
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: 8,
					borderRadius: 12,
					'&:hover': {
						backgroundColor: colors.N300,
					}
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: fontName,
				}
			}
		},
		MuiPagination: {
			styleOverrides: {
				ul: {
					border: '1px solid #263051',
					borderRadius: 8,
					'& li': {
						borderRight: '1px solid #263051',
						'&last-child': {
							borderRight: 'none',
						}
					}
				},
			}
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					border: 'none',
					height: 41,
					minWidth: 41,
					margin: 0,
					boxShadow: 'none',
					borderRadius: 0,
					fontSize: 15,
					'&.Mui-selected': {
						backgroundColor: colors.N300,
						color: colors.N900
					}
				},
				ellipsis: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					height: 31,
					borderRadius: 40,
					border: `1px solid ${colors.N500}`,
					'&.Mui-focused': {
						border: `1px solid ${colors.N700}`,
					}
				},
				input: {
					...typography.body4,
					paddingTop: 0,
					fontFamily: fontName,
					paddingBottom: 0,
					outline: 'none',
					border: 'none',
					'&::placeholder': {
						...typography.body4,
					}
				},
				notchedOutline: {
					border: 'none',
				}
			},
			variants: [
				{
					props: { size: 'large' },
					style: {
						height: 59,
						borderRadius: 12,
						'& .MuiOutlinedInput-input': {
							paddingTop: 16,
							fontFamily: fontName,
							paddingBottom: 16,
							paddingLeft: 24,
							paddingRight: 24,
							...typography.body2,
							'&::placeholder': {
								...typography.body2,
								color: colors.N600,
							}
						}
					}
				}
			]
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 'none',
				},
				footerContainer: {
					marginTop: 16,
				},
				columnHeaders: {
					backgroundColor: colors.transparentBg,
					colors: colors.N700,
					borderBottomWidth: 1,
					borderBottomColor: colors.N500,
					borderBottomStyle: 'solid',
				},
				columnHeader: {
					outline: 'none !important',
					'user-select': 'none !important',
				},
				columnHeaderTitle: {
					...typography.body3,
					color: colors.N700,
					paddingLeft: 6,
					paddingRight: 6,
					textOverflow: 'initial',
					overflow: 'initial',
					whiteSpace: 'pre-wrap',
				},
				columnSeparator: {
					display: 'none !important',
				},
				row: {
					backgroundColor: colors.transparentBg,
					cursor: 'pointer',
					'&:last-child': {
						borderBottomRightRadius: 12,
						borderBottomLeftRadius: 12,
						'& .MuiDataGrid-cell': {
							borderBottomWidth: 0,
						}
					},
					'&:hover': {
						backgroundColor: colors.transparentBgDark,
					}
				},
				cell: {
					borderBottomWidth: 1,
					borderBottomColor: colors.N300,
					borderBottomStyle: 'solid',
					padding: '0 16px',
					'&:focus-within': {
						outline: 'none !important',
					}
				},
				main: {
					borderRadius: 12,
					borderColor: colors.N500,
					borderWidth: 1,
					borderStyle: 'solid',
				},
				//@ts-ignore
				footerContainer: {
					paddingTop: 24,
					borderTop: 'none',
				},
				overlay: {
					backgroundColor: 'transparent',
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: colors.N900,
					borderRadius: 12,
					padding: 8,
					fontFamily: fontName,
					color: colors.N100,
					...typography,
				},
				arrow: {
					color: colors.N900,
					fontSize: 17,
				}
			},
			defaultProps: {
				placement: 'top',
			}
		}
	},
});

export default theme;
