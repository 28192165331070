import React, { FC } from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

const SwapIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon { ...props } viewBox="0 0 20 20">
		<path d="M13.3331 14.1749V9.1666C13.3331 8.70827 12.9581 8.33327 12.4998 8.33327C12.0414 8.33327 11.6664 8.70827 11.6664 9.1666V14.1749H10.1748C9.79978 14.1749 9.61645 14.6249 9.88311 14.8833L12.2081 17.1999C12.3748 17.3583 12.6331 17.3583 12.7998 17.1999L15.1248 14.8833C15.3914 14.6249 15.1998 14.1749 14.8331 14.1749H13.3331ZM7.20811 2.7916L4.88311 5.1166C4.61645 5.37493 4.79978 5.82493 5.17478 5.82493H6.66645V10.8333C6.66645 11.2916 7.04145 11.6666 7.49978 11.6666C7.95811 11.6666 8.33311 11.2916 8.33311 10.8333V5.82493H9.82478C10.1998 5.82493 10.3831 5.37493 10.1164 5.1166L7.79145 2.7916C7.63311 2.63327 7.36645 2.63327 7.20811 2.7916Z" />
	</SvgIcon>
)

export default SwapIcon;
