import React, { FC } from 'react';
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";

const Logo = styled('img')({
    height: 30,
    marginRight: 8,
});

const Winner: FC<{ off: boolean, driver: DriverBid }> = ({ off, driver }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', opacity: off ? 0.3 : 1 }}>
        <Logo src={driver.logo} alt={driver.name} title={driver.name} />
        <Typography variant="body0Bold">{driver.name}</Typography>
      </Box>
    )
}

export default Winner;
