import React, { FC } from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

const Right: FC<SvgIconProps> = (props) => (
	<SvgIcon { ...props } viewBox="0 0 24 24">
		<path d="M18.6238 11.4842L15.3286 8.17708C14.9611 7.79778 14.3211 8.0704 14.3211 8.59195V10.7137H7.18533C6.5334 10.7137 6 11.2471 6 11.899C6 12.551 6.5334 13.0844 7.18533 13.0844H14.3092V15.2061C14.3092 15.7395 14.9493 16.0003 15.3167 15.621L18.612 12.3139C18.849 12.0887 18.849 11.7094 18.6238 11.4842Z" />
	</SvgIcon>
)

export default Right;
