import React, {FC, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";
import Hourglass from "../icons/Hourglass";
import CountDown from "./CountDown";
import {useRequest, useDocumentVisibility, useInterval} from "ahooks";
import { getLiveActionsSwaps } from "../../api";
import PuffLoader from "react-spinners/PuffLoader";
import hammerLogo from '../../assets/hammer.png';
import Converted from "../common/Converted";
import {denySmartExtraFraction} from "../../utils";
import SwapDetailsButton from "../common/SwapDetailsButton";


function useAway(seconds: number): boolean {
    const documentVisibilityState = useDocumentVisibility();
    const [leaveTime, setLeaveTime] = useState<number | null>(null);
    const [currentTime, setCurrentTime] = useState<number>((new Date()).getTime());

    useInterval(() => {
        setCurrentTime((new Date()).getTime());
    }, 1000);

    useEffect(() => {
        if (documentVisibilityState === 'visible') {
            setLeaveTime(null);
        } else if (documentVisibilityState === 'hidden') {
            setLeaveTime((new Date()).getTime());
        }
    }, [documentVisibilityState]);

    return !!(leaveTime && (currentTime - leaveTime > seconds * 1000));
}
const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const Root = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 24,
    cursor: 'pointer',
    textDecoration: 'none',
    justifyContent: 'space-between',
    minHeight: 100,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.common.N300,
    '&:last-child': {
        borderBottom: 'none',
    }
}));

const Hammer = styled('img')({
    width: 40,
    marginRight: 24,
})
const Row: FC<{ data: SwapFullData }> = ({ data }) => {

    if (!data.notStarted && data.auctionDeadlineDate.getTime() <= new Date().getTime()) {
        return null;
    }
    return (
      <Root>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Hammer src={hammerLogo} />
              <Converted
                from={`${denySmartExtraFraction(data.fromAmount)} ${data.fromTokenSymbol}`}
                to={data.toTokenSymbol}
                typography={{ variant: 'body1' }}
              />
              <SwapDetailsButton sx={{ ml: 1.5 }} sourceTx={data.sourceTxHash} closed={false} />
          </Box>
          {data.notStarted ? (
            <Typography variant="body2Bold" color="common.lightYellow">Starting...</Typography>
          ) : (
            <Container>
                <Hourglass sx={{ mr: 0.5 }} />
                <Typography variant="body2" sx={{ mr: 1.5 }}>Remaining Time :</Typography>
                <CountDown deadline={data.auctionDeadlineDate.getTime()} />
            </Container>
          )}
      </Root>
    )
}
const LiveAuctions: FC = () => {
    const isAway = useAway(30);
    const { run, data, loading } = useRequest(async () => {
        if (!isAway) {
            return getLiveActionsSwaps();
        }
        return [] as SwapFullData[];
    }, {
        pollingInterval: 1500,
    });
    return (
      <>
          <Container sx={{ mb: 4 }}>
              <PuffLoader
                color="#672B2D"
                size={36}
              />
              <Typography variant="h1" sx={{ ml: 3 }}>Live Auctions</Typography>
          </Container>
          {data && data.length ? (
            data.map(d => (
              <Row data={d} key={d.sourceTxHash} />
            ))
          ) : (
            <Typography variant="body2" sx={{ ml: 1, mb: 1 }}>There are no live auctions at the moment...</Typography>
          )}
      </>

    )
}

export default LiveAuctions;
