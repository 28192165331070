import React, { FC } from 'react';
import SwapIcon from './SwapIcon';
import ExplorerIcon from "./ExplorerIcon";
import AuctionIcon from "./AuctionIcon";
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Item = styled(Box, {
	shouldForwardProp: (propName: PropertyKey) => propName !== 'current',
})<{ current?: boolean }>(({ theme, current = false }) => ({
	marginRight: 8,
	padding: '8px 16px',
	borderRadius: 24,
	height: 43,
	display: 'flex',
	justification: 'center',
	alignItems: 'center',
	textDecoration: 'none',
	color: theme.palette.common.N900,
	backgroundColor: current ? theme.palette.common.tBlueLight : 'transparent',
	'&:hover': {
		color: current ? theme.palette.common.N900 : theme.palette.common.N700,
	},
	'&:last-child': {
		marginRight: 0,
	}
}));

const AppMenu: FC = () => {

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }} >
			{/*
			// @ts-ignore */}
			<Item component={Link} target="_blank" href={process.env.REACT_APP_MAYAN_SWAP_APP}>
				<Typography variant="body2Bold" sx={{ mr: 0.5 }}>Swap</Typography>
				<SwapIcon />
			</Item>
			{/*
			// @ts-ignore */}
			<Item component={Link} target="_blank" href={process.env.REACT_APP_MAYAN_EXPLORER_APP}>
				<Typography variant="body2Bold" sx={{ mr: 0.5 }}>Explorer</Typography>
				<ExplorerIcon />
			</Item>
			<Item current>
				<Typography variant="body2Bold" sx={{ mr: 0.5 }}>Auctions</Typography>
				<AuctionIcon />
			</Item>
		</Box>
	)
}

export default AppMenu;
