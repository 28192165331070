import {chains, drivers} from "../configs";
import {Connection, PublicKey} from '@solana/web3.js'
import { ethers } from 'ethers';
import { getMint } from '@solana/spl-token';

const connection = new Connection(process.env.REACT_APP_SOLANA_RPC);

export function denyExtraFraction(value?: number, fraction = 6, toLocalString = false) : string{
	if(!Number(value) && Number(value) !== 0) {
		return ''
	}
	const result = Math.floor(Number(value) * (10 ** fraction)) / (10 ** fraction)
	if (toLocalString) {
		return result.toLocaleString(undefined, { maximumFractionDigits : fraction })
	}
	return String(result);
}

export function denySmartExtraFraction(input?: number | string | null, toLocalString = false) : string {
	if(!Number(input) && Number(input) !== 0) {
		return ''
	}
	const value = Number(input);
	let precision =  8
	if(value < 1) {
		const str = String(value);
		const x = str.split('.');
		precision = x[1] ? Math.max(x[1].search(/[1-9]/) + 3, 6) : 0
	}
	const fraction = Math.max(2, precision - value.toFixed().length)
	const result = Math.floor(Number(value) * (10 ** fraction)) / (10 ** fraction)
	if (toLocalString) {
		return result.toLocaleString(undefined, { maximumFractionDigits : fraction })
	}
	return String(result);
}


export function wait(time: number) : Promise<void> {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, time);
	});
}

export function getAmountOfFractionalAmount(amount: string | number, decimals: string | number) : ethers.BigNumber {
	const fixedAmount = Number(amount).toFixed(Math.min(8, Number(decimals)));
	return ethers.utils.parseUnits(fixedAmount, Number(decimals))
}

export function getDisplayAmount(inputAmount: ethers.BigNumberish, decimals: string | ethers.BigNumberish) : number {
	return  Number(ethers.utils.formatUnits(inputAmount, decimals))
}

export function getChainByChainId(chainId: number): Chain | undefined {
	return  chains.find(c => c.chainId === chainId);
}

export async function getCurrentSolanaTime() : Promise<number> {
	const res = await fetch(`${process.env.REACT_APP_MAYAN_PRICE_URL}/clock/solana`);
	const result = await res.json();
	if (res.status !== 200) {
		throw result;
	}
	return result.clock;
}

export function getShortAddress(address: string) :string {
	if (!address || address?.length < 12) {
		return address;
	}
	return address.slice(0, 6) + ' ... ' + address.slice(address.length - 4)
}

export function getChainByWChainId(wChainId: number) : Chain | undefined {
	return  chains.find(c => c.wChainId === wChainId);
}

class SwapState {
	static async parseStateToTokenDecimals(stateData: Buffer): Promise<number> {
		const mintAddress = new PublicKey(stateData.slice(113, 113 + 32)).toString();
		const mint = await getMint(connection, new PublicKey(mintAddress));
		return mint.decimals
	}
	static parseStateAmountOut(stateData: Buffer): string {
		return String(stateData.readBigInt64LE(65));
	}
}

class AuctionState {
	static parseWinner(auctionData: Buffer): string {
		return new PublicKey(auctionData.slice(32, 32 + 32)).toString();
	}

	static parseAmount(auctionData: Buffer): Number {
		return Number(auctionData.readBigInt64LE(64));
	}

	static parseValidFrom(auctionData: Buffer): Date {
		return new Date(Number(auctionData.readBigInt64LE(72)) * 1000);
	}

	static parseValidUntil(auctionData: Buffer): Date {
		return new Date(Number(auctionData.readBigInt64LE(80)) * 1000);
	}
}

class BidState {
	static parseAmount(bidData: Buffer): string {
		return String(bidData.readBigInt64LE(0));
	}
}
const AUCTION_PROGRAM_ADDRESS = new PublicKey("4U6MNAMRbYeTD4HumavhJ1ah9NQ5pNPhTNxcFdeybXFy")

async function getDriverBidInfo(
	driver: Driver, winner: PublicKey, auctionStateAddr: PublicKey, decimals: number,
): Promise<Driver & { bid: number, isWinner: boolean } | null> {
	const [bidAddr] = PublicKey.findProgramAddressSync(
		[
			Buffer.from('BID'),
			Buffer.from(auctionStateAddr.toBytes()),
			Buffer.from(driver.address.toBytes()),
		],
		AUCTION_PROGRAM_ADDRESS,
	);
	const bidInfo = await connection.getAccountInfo(bidAddr, 'processed');
	if (!bidInfo) {
		return null
	}
	const amount = getDisplayAmount(String(BidState.parseAmount(bidInfo.data)), decimals);
	return {
		...driver,
		bid: amount,
		isWinner: winner.equals(driver.address),
	}
}


export async function getSwapFullData(
	input: TradeListItemResponse,
	live = false
) : Promise<SwapFullData | null> {
	const stateAddr = new PublicKey(input.stateAddr);
	const stateInfo = await connection.getAccountInfo(stateAddr, 'processed');
	if (!stateInfo && !live) {
		console.log('stateInfo not found')
		return null;
	}
	const [auctionStateAddr] = PublicKey.findProgramAddressSync(
		[Buffer.from('AUCTION'), stateAddr.toBytes()],
		AUCTION_PROGRAM_ADDRESS
	);

	const auctionData = await connection.getAccountInfo(auctionStateAddr, 'processed');
	if (!auctionData && !live) {
		console.log('auctionData not found')
		return null;
	}
	let winner: PublicKey;
	let auctionDeadlineDate: Date;
	let auctionStartDate: Date;
	let notStarted: boolean;

	if (auctionData) {
		winner = new PublicKey(AuctionState.parseWinner(auctionData.data));
		auctionDeadlineDate = AuctionState.parseValidFrom(auctionData.data);
		auctionStartDate = new Date(auctionDeadlineDate.getTime() - (30 * 1000));
		notStarted = false;
	} else {
		notStarted = true;
	}

	const mintToDecimals = stateInfo ? await SwapState.parseStateToTokenDecimals(stateInfo.data) : null;

	let driversInfos = !live && auctionData && stateInfo ? await Promise.all(
		drivers.map(d => getDriverBidInfo(d, winner, auctionStateAddr, mintToDecimals))
	) : null;
	const amountOut = !live && stateInfo ? getDisplayAmount(SwapState.parseStateAmountOut(stateInfo.data), mintToDecimals) : null;
	driversInfos = driversInfos ? driversInfos
		.filter(d => !!d)
		.sort((d1, d2) => d1.bid < d2.bid ? 1 : -1 ) : null;

	return {
		...input,
		notStarted,
		amountOut,
		driversInfos,
		auctionDeadlineDate,
		auctionStartDate,
	}
}

export function parseTradeListItemResponse(input: any) : TradeListItemResponse {
	const sourceChain = getChainByWChainId(Number(input.sourceChain));
	const destChain = getChainByWChainId(Number(input.destChain));
	const swapChain = getChainByWChainId(Number(input.swapChain));
	return {
		fromAmount: Number(input.fromAmount),
		stateAddr: input.stateAddr,
		destChain,
		fromTokenSymbol: input.fromTokenSymbol,
		id: input.sourceTxHash,
		initiatedAt: input.initiatedAt,
		sourceChain,
		status: input.status,
		swapChain,
		toAmount: Number(input.toAmount),
		toTokenSymbol: input.toTokenSymbol,
		trader: input.trader,
		sourceTxHash: input.sourceTxHash,
		isCompleted: !!(input?.status === 'REDEEMED_ON_EVM' || input?.status === 'REDEEMED_ON_APTOS' || input?.status === 'SETTLED_ON_SOLANA'),
		isRefunded: !!(input?.status === 'REFUNDED_ON_SOLANA' || input?.status === 'REFUNDED_ON_EVM')
	}
}
