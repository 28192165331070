import React, { FC } from "react";
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Header from "./Header";
import Home from "./Home";

const App: FC = () => {
	return(
		<BrowserRouter>
			<Box sx={{ px: 4.5, pb: 6, minHeight: 'calc(100vh - 28px)' }} >
				<Header />
				<Box sx={{ mt: 4.5, px: 1.5, maxWidth: 1344, mx: 'auto' }} >
					<Routes>
						<Route index element={<Home />} />
					</Routes>
				</Box>
			</Box>
		</BrowserRouter>
	)
};

export default App;
