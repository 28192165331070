import axios from "axios";
import queryString from "querystring";
import {getSwapFullData, parseTradeListItemResponse} from "../utils";

function wait(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
export async function getClosedActionsSwaps(): Promise<{ data: SwapFullData[], count: number }> {
	const params = {
		status: 'post_swap',
		limit: 50,
		offset: 0,
	};
	const { data: { data, metadata } } = await axios.get(`${process.env.REACT_APP_MAYAN_EXPLORER_URL}/swaps?${queryString.stringify(params)}`)
	let result: SwapFullData[] = [];
	const batchs = [
		data.slice(0, 7),
		data.slice(7, 14),
		data.slice(14, 21),
		data.slice(21, 28),
		data.slice(28, 35),
		data.slice(35, 42),
		data.slice(42, 50),
	];
	for (let i = 0; i < batchs.length; i++) {
		const swaps = batchs[i];
		let swapsFullData = await Promise.all(swaps.map((d: any) => getSwapFullData(parseTradeListItemResponse(d))));
		swapsFullData = swapsFullData.filter(r => !!r);
		if (swapsFullData) {
			result = result.concat(swapsFullData);
		}
	}
	return {
		data: result,
		count: metadata.count,
	};
}

export async function getLiveActionsSwaps(): Promise<SwapFullData[]> {
	const params = {
		status: 'CLAIMED_ON_SOLANA',
		limit: 100,
		offset: 0,
	};
	const { data: { data } } = await axios.get(`${process.env.REACT_APP_MAYAN_EXPLORER_URL}/swaps?${queryString.stringify(params)}`)
	let result = await Promise.all(data.map((d: any) => getSwapFullData(parseTradeListItemResponse(d), true)));
	result = result.filter(r => !!r);
	return result;
}
