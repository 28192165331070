import React from 'react';
import ReactDOM from "react-dom";
import 'no-darkreader';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from "@mui/material/Container";
import theme from './theme';
import App from "./components/App";

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<>
				<Container></Container>
				<App />
			</>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
)
