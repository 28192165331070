import React, {FC, useEffect, useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DriversBox from "./DriversBox";
import moment from "moment";
import Winner from "./Winner";
import ExpandMore from '@mui/icons-material/ExpandMoreRounded';
import ExpandLess from '@mui/icons-material/ExpandLessRounded';
import SwapDetailsButton from "../common/SwapDetailsButton";
import styled from "@mui/system/styled";
import Converted from "../common/Converted";
import Collapse from "@mui/material/Collapse";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {CircularProgress, IconButton} from "@mui/material";
import { useRequest, useDocumentVisibility } from 'ahooks';
import {getClosedActionsSwaps} from "../../api";
import {denySmartExtraFraction} from "../../utils";
import Chains from "../common/Chains";
import mitikomon from '../../assets/mitikomon.svg'

const Container = styled(Box)({
    display: "flex",
    alignItems: "center",
});

const StyledTableTopRow = styled(TableRow, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'open'
})<{ open: boolean }>(({ theme, open }) => ({
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: open ? theme.palette.common.N000 : 'transparent',
    '& .MuiTableCell-root': {
        borderBottomWidth: open ? 0 : 1,
        paddingBottom: open ? 8 : 16,
    },
    '&:hover': {
        backgroundColor: open ? theme.palette.common.N000 : theme.palette.common.N100,
        '& .MuiLink-root': {
            backgroundColor: theme.palette.common.N300,
        },
    }
}));

const StyledTableBottomRow = styled(TableRow, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'open'
})<{ open: boolean }>(({ theme, open }) => ({
    backgroundColor: theme.palette.common.N000,
    '& .MuiTableCell-root': {
        borderBottomWidth: open ? 1 : 0,
    }
}));

const DriverInfoContainer = styled(TableCell)(({theme}) => ({
    padding: '12px 76px'
}));

const DriversTableContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    marginBottom: 20,
    marginTop: 12,
    backgroundColor: theme.palette.common.N100,
    borderRadius: 12,
    padding: '0 20px 16px',
    '& .MuiTableCell-root': {
        borderBottomWidth: 1,
    },
    '& .MuiTableCell-head': {
        paddingBottom: 0,
        borderBottomWidth: 0,
    },
    '& .MuiTableRow-root': {
        '&:last-child': {
            '& .MuiTableCell-root': {
                borderBottomWidth: 0,
            },
        }
    }
}));

const Mitikomon = styled('img')({
    width: 32,
});

const DriverLogoSmall = styled('img')({
    width: 24,
    marginRight: 12,
});

const Row: FC<{ data: SwapFullData }> = ({ data }) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClickOpenIcon = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(!open);
    }
    const winner = useMemo<DriverBid>(() => data.driversInfos.find(d => d.isWinner), [data]);
    return (
      <>
          <StyledTableTopRow open={open} onClick={handleClickOpenIcon}>
              <TableCell sx={{ width: 40, pr: 0, pl: 1 }}>
                  <IconButton
                    sx={{ borderRadius: '50%' }}
                    onClick={handleClickOpenIcon}
                  >
                      {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
              </TableCell>
              <TableCell><DriversBox off={open} drivers={data.driversInfos} /></TableCell>
              <TableCell>{moment(data.auctionStartDate).format('DD MMM YYYY HH:mm')}</TableCell>
              <TableCell>
                  <Container>
                      <Converted
                        from={`${denySmartExtraFraction(data.fromAmount)} ${data.fromTokenSymbol}`}
                        to={`${denySmartExtraFraction(data.amountOut)} ${data.toTokenSymbol}`}
                        typography={{variant: 'body3'}}
                      />
                      <SwapDetailsButton sourceTx={data.sourceTxHash} sx={{ ml: 1.25 }} closed />
                  </Container>
              </TableCell>
              <TableCell><Winner off={open} driver={winner} /></TableCell>
          </StyledTableTopRow>
          <StyledTableBottomRow open={false}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2} />
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse in={open} easing="linear" timeout="auto" unmountOnExit>
                      <Box sx={{ mb: 2 }}>
                          <Typography variant="body3" color="common.N600">Auction Duration</Typography>
                          <Typography variant="body2" >00:30:00s</Typography>
                      </Box>
                  </Collapse>
              </TableCell>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                  <Collapse in={open} easing="linear" timeout="auto" unmountOnExit>
                      <Box sx={{ mb: 2 }}>
                          <Typography variant="body3" color="common.N600">Chains</Typography>
                          <Chains data={data} />
                      </Box>
                  </Collapse>
              </TableCell>
          </StyledTableBottomRow>
          <StyledTableBottomRow open={open}>
              <DriverInfoContainer style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={open} easing="linear" timeout="auto" unmountOnExit>
                      <DriversTableContainer>
                          <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableCell sx={{ width: 40, pr: 0, pl: 1 }} />
                                      <TableCell><Typography variant="body3" color="common.N700">Driver</Typography></TableCell>
                                      <TableCell><Typography variant="body3" color="common.N700">Bid Amount</Typography></TableCell>
                                      <TableCell><Typography variant="body3" color="common.N700">Swap Amount</Typography></TableCell>
                                      <TableCell />
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {data.driversInfos.map(d => (
                                    <TableRow key={d.id}>
                                        <TableCell sx={{ width: 40, pr: 0, pl: 1 }}>
                                            {d.isWinner && (
                                              <Container>
                                                  <Mitikomon src={mitikomon} />
                                              </Container>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Container>
                                                <DriverLogoSmall src={d.logo} />
                                                <Typography variant={d.isWinner ? "body1Bold" : "body1"}>{d.name}</Typography>
                                            </Container>
                                        </TableCell>
                                        <TableCell><Typography variant="body3">{denySmartExtraFraction(d.bid)} {data.toTokenSymbol}</Typography></TableCell>
                                        <TableCell>
                                            {d.isWinner && (
                                              <Typography variant="body3">{denySmartExtraFraction(data.amountOut)} {data.toTokenSymbol}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </DriversTableContainer>
                  </Collapse>
              </DriverInfoContainer>
          </StyledTableBottomRow>
      </>
    )
}

const MemoizedRow = React.memo(
  Row, (prev, next) => prev?.data?.sourceTxHash === next?.data?.sourceTxHash);

const ClosedAuctions: FC = () => {
    const documentVisibilityState = useDocumentVisibility();
    const { loading } = useRequest(async () => {
        if (documentVisibilityState === 'visible') {
            return getClosedActionsSwaps();
        }
        return null;
    }, {
        // pollingInterval: 10 * 1000,
        onSuccess: (d) => {
            if (d === null) {
                return;
            } else {
                if (d?.data) {
                    setAllDate(d.data);
                }
            }
        }
    });
    const [allDate,  setAllDate] = useState<SwapFullData[]>([]);



    return (
        <>
            <Box sx={{ width: 1 }} >
                <Box sx={{ mb: 1.5 }} >
                    <Typography variant="h1">Closed Auctions</Typography>
                </Box>
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 40, pr: 0, pl: 1 }} />
                            <TableCell><Typography variant="body3" color="common.N700">Drivers</Typography></TableCell>
                            <TableCell><Typography variant="body3" color="common.N700">Start Date</Typography></TableCell>
                            <TableCell><Typography variant="body3" color="common.N700">Swap</Typography></TableCell>
                            <TableCell align="center"><Typography variant="body3" align="center" color="common.N700" >Winner</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allDate.map(d => (
                          <MemoizedRow data={d} key={d.sourceTxHash} />
                        ))}
                    </TableBody>
                </Table>
            </Box>

        </>
    );
}

export default ClosedAuctions;
