import React, {FC, useEffect, useState} from 'react';
import {useInterval} from "ahooks";
import Typography from "@mui/material/Typography";

const CountDown: FC<{ deadline: number }> = ({ deadline }) => {
    const [timer, setTimer] = useState<number>(null);
    useEffect(() => {
        const value = Math.floor(((deadline + 2000) -  (new Date().getTime())) / 1000);
        if (value <= 0) {
            setTimer(null);
        } else {
            setTimer(value);
        }
    });
    useInterval(() => {
        if (timer > 0) {
            setTimer(prevState => prevState - 1);
        } else {
            setTimer(null)
        }
    }, 1000);
    if (timer) {
        return <Typography variant="body2Bold" color="common.lightYellow">{timer}s</Typography>
    }
    return null;
}

export default CountDown;
