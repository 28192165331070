import React, { FC } from 'react';
import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";

const Root = styled(Link)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'nowrap',
	color: theme.palette.common.primary,
	textDecoration: 'none',
	'&:hover': {
		color: '#94B8FF',
	}
}));

export const Icon = styled(OpenInNew)(({theme}) => ({
	color: theme.palette.common.primary,
	fontSize: '1.5rem',
	marginLeft: 8,
	'&:hover': {
		color: '#94B8FF',
	}
}));

const OutLink: FC<{ title: string, href: string, wrap?: boolean }> = ({title, href, wrap = false}) => {
	return (
		<Root href={href} target="_blank">
			<Typography variant="body3" sx={{ wordBreak: wrap ? 'break-word' : 'normal' }}>{title}</Typography>
			<Icon />
		</Root>
	);
}

export default OutLink;
